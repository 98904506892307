import React, { Component } from 'react';
import $ from 'jquery';
class alertMessage extends Component {
       constructor(props){
              super(props);
       }
       // alertDivSlideUp(evt){
       //        $("#alert-div").slideUp(500);
       // }
       render(){ 
            return ( 
                     <div className={"alert alert-"+this.props.type}>
                            {this.props.message}
                     </div>
                   );
            }
       }
export default alertMessage;

