import React, { Component } from 'react';
import axios from 'axios';
import AlertMessage from '../message/alertMessage';
import Loader from "../loader/loaderComponent";
import { Redirect } from 'react-router'
import "./contact.css";
import $ from 'jquery';
const baseApiUrl = "https://proweb.ae/cruisemag/";
class Contact extends Component {
    constructor(){
        super();
        this.onSubmitContactUs=this.onSubmitContactUs.bind(this)
        this.onChangeContactName=this.onChangeContactName.bind(this)
        this.onChangeContactEmail=this.onChangeContactEmail.bind(this)
        this.onChangeContactMessage=this.onChangeContactMessage.bind(this)
        this.state={
            loading: false,
            redirect: false,
            name : '',
            email : '',
            message : '',
            msgtype : '',
            alertmsg : ''
        }
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
    onChangeContactName(evt){
        this.setState({
            name: evt.target.value
          });
    }
    onChangeContactEmail(evt){
        this.setState({
            email: evt.target.value
          });
    }
    onChangeContactMessage(evt){
        this.setState({
            message: evt.target.value
          });
    }
    onSubmitContactUs(e){
        e.preventDefault();
        this.setState({ loading: true });
        const data = {
            name : this.state.name,
            email : this.state.email,
            message : this.state.message
        }
        axios.post(baseApiUrl+'api/contact',data).then(response=>{
            if(response.data.data === "Inserted Succesfully.")
            {
                this.setState({msgtype:'success'})
                this.setState({alertmsg: "Thank you! we'll contact you soon."})
                this.setState({name:''})
                this.setState({email:''})
                this.setState({message:''})
                this.setState({ loading: false })
                this.setState({ redirect: true })
                $(".contactAlert").fadeIn(100);
                $(".contactAlert").fadeOut(5000);
            }
            else
            {
                this.setState({msgtype:'danger'})
                this.setState({alertmsg:response.data.errors})
                this.setState({ loading: false })
                $(".contactAlert").fadeIn(100);
                $(".contactAlert").fadeOut(5000);
            }
        });

    }
    render() { 
        const { redirect } = this.state;
        if (redirect) {
            return <Redirect to='/beta/thankyou'/>;
        }
        return ( 
            <div className="container-fluid bg-layout page-gap d-flex">
                {/* loader */}
                { this.state.loading &&  <Loader/> }

                <div className="container align-self-center">
                    <h2 className="sec-heading">Contact Us</h2>
                    <div className="form-layout">
                        <h5 className="sub-heading">Email us with any questions or inquires in the form below. We  would be happy to answer your questions.</h5>
                        <div className="contactAlert">{ this.state.alertmsg ? <AlertMessage message={this.state.alertmsg} type={this.state.msgtype}/> : null }</div>
                        <form className="contact-form" method="post" onSubmit={this.onSubmitContactUs}>
                            <div className="form-group">
                                <input className="form-control" required="required" type="text" name="name" value={this.state.name} onChange={this.onChangeContactName} placeholder="Your name *" />
                            </div>
                            <div className="form-group">
                                <input className="form-control" required="required" type="email" name="email" value={this.state.email} onChange={this.onChangeContactEmail} placeholder="Your email *" />
                            </div>
                            <div className="form-group">
                                <textarea className="form-control" required="required" rows="5" name="message" value={this.state.message} onChange={this.onChangeContactMessage} placeholder="Your message *" ></textarea>
                            </div>
                            <div className="text-center">
                                <button className="btn blue-btn w-100" type="submit">
                                    SEND
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default Contact;