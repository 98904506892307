import React, { Component } from 'react';
import "./related.css";
import axios from 'axios';
import InfiniteCarousel from 'react-leaf-carousel';
import {Link} from 'react-router-dom';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const baseArtImgThumbUrl = "https://proweb.ae/cruisemag/public/uploads/admin/article_image/thumbnails/";
const baseApiUrl = "https://proweb.ae/cruisemag/";
const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1201 },
      items: 6,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1200, min: 768 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 767, min: 300 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
class Relatedpost extends Component {
    constructor(props){
        super(props);
        this.state={
            related:[]
        }
    }
    componentDidMount(){
        axios.get(baseApiUrl+'api/article').then(response=>{
            this.setState({related:response.data.data});
        });
    }
    render() { 
        return ( 
        <div>
            <Carousel responsive={responsive}
                autoPlay={this.props.deviceType !== "mobile" ? true : false}
                infinite={true}
                draggable={false}
                removeArrowOnDeviceType={["mobile"]}
            >
                {
                    this.state.related.map((relate, index)=>{
                    return(
                        <div className="slide-item" key={index}>
                            <Link to={"/article/"+relate.id} key={relate.id}>
                                <img alt={relate.title} className="slide-img" src={baseArtImgThumbUrl+relate.article_thumb} />
                                <div className="img-overlay">
                                    <h5 className="overlay-title">{relate.title}</h5>
                                </div>
                            </Link>
                        </div>
                        )
                    })
                }
            </Carousel>
        </div>
         );
    }
}
export default Relatedpost;