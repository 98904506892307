import React, { Component } from 'react';
import "./footer.css";
import axios from 'axios';
import AlertMessage from '../message/alertMessage';
import map from '../../assets/images/map.png';
import {Link} from 'react-router-dom';
import Loader from "../loader/loaderComponent";
import $ from 'jquery';
import CookieConsent from "react-cookie-consent";
const baseApiUrl = "https://proweb.ae/cruisemag/";
class Footer extends Component {
    constructor(){
        super();
        this.onSubmitSubscribe=this.onSubmitSubscribe.bind(this)
        this.onChangeSubscribeEmail=this.onChangeSubscribeEmail.bind(this)
        this.state={
            loading: false,
            email_id : '',
            msgtype : '',
            message : ''
        }
        window.scrollTo({top: 0, behavior: 'smooth'});
    }
    onChangeSubscribeEmail(evt){
        this.setState({
            email_id: evt.target.value
          });
    }
    onSubmitSubscribe(e){
        e.preventDefault();
        this.setState({ loading: true });
        const email = {
            email : this.state.email_id
        }
        axios.post(baseApiUrl+'api/subscription',email).then(response=>{
            if(response.data.data === "Succesfully Subscribed.")
            {
                this.setState({msgtype:'success'})
                this.setState({message:'Subscribed Successfully.'})
                this.setState({email_id:''})
                this.setState({ loading: false })
                $(".subscriptionAlert").fadeIn(100);
                $(".subscriptionAlert").fadeOut(4000);
            }
            else
            {
                this.setState({msgtype:'danger'})
                this.setState({message:response.data.errors})
                this.setState({ loading: false })
                $(".subscriptionAlert").fadeIn(100);
                $(".subscriptionAlert").fadeOut(4000);
            }
        });

    }
    render() { 
        return ( 
            <React.Fragment>
                {/* loader */}
                { this.state.loading &&  <Loader/> }
                
                <div className="container-fluid footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div>
                                    <div className="hr_text footer-head"><span>WHERE WE ARE NOW</span></div>
                                    <div className="map">
                                        <img src={map} alt="our-location" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <div className="hr_text footer-head"><span>FOLLOW US</span></div>
                                    <ul className="social-icon">
                                        <li>
                                            <a href="https://www.instagram.com/cruisemagazineen/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i> <span>INSTAGRAM</span></a>
                                        </li>
                                        <li>
                                        <a href="https://www.facebook.com/" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i> <span>FACEBOOK</span></a>
                                        </li>
                                        <li>
                                        <a href="https://twitter.com/cruise_mag" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i> <span>TWITTER</span></a>
                                        </li>
                                        <li>
                                        <a href="https://www.pinterest.com/cruisemagazineen/" target="_blank"><i className="fa fa-pinterest-p" aria-hidden="true"></i> <span>PINTEREST</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div>
                                    <div className="hr_text footer-head"><span>NEWSLETTER</span></div>
                                    <div className="subscriptionAlert">{ this.state.message ? <AlertMessage message={this.state.message} type={this.state.msgtype}/> : null }</div>
                                    <form className="form-inline subs-form" method="post" onSubmit={this.onSubmitSubscribe}>
                                        <input className="form-control" required="required" name="email" type="email" value={this.state.email_id} onChange={this.onChangeSubscribeEmail} placeholder="Enter your email" />
                                        <button className="btn blue-btn" type="submit">
                                            SUBSCRIBE
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <ul>
                                    <li><Link to="/terms&conditions" className="highlight">Terms & Conditions</Link></li>
                                    <li>|</li>
                                    <li><Link to="/privacypolicy" className="highlight">Privacy Policy</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <div className="text-right">
                                    2020 © All rights reserved | <Link to="/" className="highlight">CruiseMag</Link>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
                
                {/* save cookies div */}
                <CookieConsent
                    location="bottom"
                    buttonText="I Understand"
                    cookieName="cookiesbox"
                    style={{ background: "#4ec5c9", fontSize: "14px" }}
                    buttonStyle={{ background: "#f9f5f2", color: "#838383", fontSize: "14px", borderRadius: "5px", margin: "5px 15px", alignSelf: "center" }}
                >
                    We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.
                </CookieConsent>
            </React.Fragment>
         );
    }
}
export default Footer;