import React, { Component } from 'react';
import "./blogdetail.css";
import axios from 'axios';
import AlertMessage from '../message/alertMessage';
import ReactHtmlParser from 'react-html-parser';
import Loader from "../loader/loaderComponent";
import {Link} from 'react-router-dom';
import Relatedpost from "../relatedpostslider/relatedComponent";
import $ from 'jquery';
import {
    FacebookShareButton,
    FacebookMessengerShareButton,
    FacebookShareCount,
    PinterestShareButton,
    PinterestShareCount,
    TwitterShareButton,
    WhatsappShareButton,
    EmailShareButton,
    TelegramShareButton,
    InstapaperShareButton,
  } from "react-share";
const baseArtImgUrl = "https://proweb.ae/cruisemag/public/uploads/admin/article_image/";
const baseArtImgThumbUrl = "https://proweb.ae/cruisemag/public/uploads/admin/article_image/thumbnails/";
const baseApiUrl = "https://proweb.ae/cruisemag/";
class Blogdetail extends Component {
    constructor(props){
        super(props);
        this.commenterName=this.commenterName.bind(this)
        this.commenterEmail=this.commenterEmail.bind(this)
        this.commenterComment=this.commenterComment.bind(this)
        this.submitComment=this.submitComment.bind(this)
        this.getData=this.getData.bind(this)
        this.state={
            // id : this.props.match.params.id,
            loading: false,
            latest_articles:[],
            categories:[],
            article:[],
            article_tags:[],
            prev_article:[],
            next_article:[],
            comments:[],
            commentername:'',
            commenteremail:'',
            commentercomment:'',
            msgtype : '',
            alertmsg : '',
        }
    }
    componentDidMount() {
        this.setState({ loading: true, alertmsg: '' }, () => {
            this.getData(this.props.match.params.id);
            window.scrollTo({top: 0, behavior: 'smooth'});
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.match.params.id !== this.props.match.params.id) {
            this.setState({ loading: true, alertmsg: '' }, () => {
                this.getData(this.props.match.params.id); 
                window.scrollTo({top: 0, behavior: 'smooth'});
            });
        }
    }
    getCategoryInputValue = (category) =>{
        this.props.searchInput(category);
    }
    getData(id) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        axios.all([
            axios.get(baseApiUrl+'api/article/'+id, {
                cancelToken: source.token
              }),
            axios.get(baseApiUrl+'api/latest-article/'+id, {
                cancelToken: source.token
              }),
            axios.get(baseApiUrl+'api/comment/'+id, {
                cancelToken: source.token
              }),
            axios.get(baseApiUrl+'api/category', {
                cancelToken: source.token
              }),
            axios.get(baseApiUrl+'api/article-tags/'+id, {
                cancelToken: source.token
              })
          ])
          .then(axios.spread((articleRes,latestArtRes,commentWithIdRes,allCommentRes,articleTagsRes) => {
            this.setState({article:articleRes.data.data});
            this.setState({prev_article:articleRes.data.data.previous});
            this.setState({next_article:articleRes.data.data.next});
            this.setState({latest_articles:latestArtRes.data.data});
            this.setState({comments:commentWithIdRes.data.data});
            this.setState({categories:allCommentRes.data.data});
            this.setState({article_tags:articleTagsRes.data.data});
            this.setState({ loading: false, });
          }));
    }
    commenterName(evt){
        this.setState({
            commentername: evt.target.value
          });
    }
    commenterEmail(evt){
        this.setState({
            commenteremail: evt.target.value
          });
    }
    commenterComment(evt){
        this.setState({
            commentercomment: evt.target.value
          });
    } 
    submitComment(e){
        e.preventDefault();
        this.setState({ loading: true });
        const data = {
            article_id : this.props.match.params.id,
            name : this.state.commentername,
            email : this.state.commenteremail,
            comment_text : this.state.commentercomment
        }
        axios.post(baseApiUrl+'api/comment',data).then(response=>{
            if(response.data.data === "Comment Inserted Succesfully.")
            {
                axios.get(baseApiUrl+'api/comment/'+this.props.match.params.id).then(response=>{
                    this.setState({comments:response.data.data});
                });
                this.setState({msgtype:'success'})
                this.setState({alertmsg: "Thank you for submitting your comment."})
                this.setState({commentername:''})
                this.setState({commenteremail:''})
                this.setState({commentercomment:''})
                this.setState({ loading: false })
                $(".commentAlert").fadeIn(100);
                $(".commentAlert").fadeOut(4000);
            }
            else
            {
                this.setState({msgtype:'danger'})
                this.setState({alertmsg:response.data.errors})
                this.setState({ loading: false })
                $(".commentAlert").fadeIn(100);
                $(".commentAlert").fadeOut(4000);
            }
        });
    }
    urlencode(str) {
        str = (str + '').toString();
        str = str.toLowerCase();
        
        return encodeURIComponent(str)
          .replace('!', '%21')
          .replace('\'', '%27')
          .replace('(', '%28')
          .replace(')', '%29')
          .replace('*', '%2A')
          .replace('%20', '-')
          .replace('%20', '-')
          .replace('%20', '-')
          .replace('%20', '-');
      }

    render() {  
        const shareUrl = String(window.location);
        const title = this.state.article.title;
        return ( 
            <React.Fragment>
                {/* loader */}
                { this.state.loading &&  <Loader/> }
                <div className="container-fluid page-gap">
                    <div className="container">
                        <div className="row">
                            {/* left column */}
                            <div className="col-md-12 col-lg-9">
                                {/* article section */}
                                <div className="post-wrap">
                                    <div className="post-img">
                                        <img src={ baseArtImgUrl+this.state.article.article_image}  />
                                    </div>
                                    <div className="post-title">
                                        <h2>{this.state.article.title}</h2>
                                        <h5>{this.state.article.location}</h5>
                                        <h6 className="post-cat">{this.state.article.category}</h6>
                                    </div>
                                    <div className="post-content">
                                        <div>{ ReactHtmlParser(this.state.article.description) }</div>
                                    </div>
                                </div>
                                {/* post author, date, share section */}
                                <div className="auth-div">
                                    <div className="row d-flex">
                                        <div className="col-6 col-md-3 col-lg-6 auth-col1 align-self-center">
                                            <h5>Author</h5>
                                        </div>
                                        {/* visible only on tablet */}
                                        <div className="d-none d-md-block d-lg-none col-md-6 auth-col2 align-self-center">
                                            <ul className="share-post">
                                                <li>
                                                    <WhatsappShareButton
                                                        url={shareUrl}
                                                        quote={title}
                                                        separator=":: "
                                                    >
                                                        <i className="fa fa-whatsapp" aria-hidden="true"></i>
                                                
                                                    </WhatsappShareButton>
                                                </li>
                                                <li>
                                                    <FacebookShareButton
                                                        url={shareUrl}
                                                        quote={title}
                                                    >
                                                    <i className="fa fa-facebook" aria-hidden="true"></i>
                                                    </FacebookShareButton>
                                                </li>
                                                <li>
                                                    <TwitterShareButton
                                                        url={shareUrl}
                                                        quote={title}
                                                    >
                                                    <i className="fa fa-twitter" aria-hidden="true"></i>
                                                    </TwitterShareButton>
                                                </li>
                                                <li>
                                                    <PinterestShareButton
                                                        url={String(window.location)}
                                                        media={ baseArtImgUrl+this.state.article.article_image}
                                                    >
                                                    <i className="fa fa-pinterest-p" aria-hidden="true"></i>
                                                    </PinterestShareButton>
                                                </li> 
                                            </ul>
                                        </div>
                                        {/* visible only on tablet */}
                                        <div className="col-6 col-md-3 col-lg-6 auth-col3 align-self-center">
                                            <h5>{this.state.article.created_at}</h5>
                                        </div>
                                    </div>
                                </div>
                                {/* next and previous post section */}
                                <div className="post-controller-div">
                                    <div className="row">
                                        
                                        <div className="col-6">
                                        { this.state.prev_article &&
                                        <Link to={"/article/"+this.state.prev_article.id}>
                                            <div className="media post-controller-left d-flex">
                                                <div className="media-left align-self-center">
                                                    <img src={baseArtImgUrl+this.state.prev_article.article_image} width="100%" />
                                                </div>
                                                <div className="media-body align-self-center">
                                                    <h5 className="media-heading">PREVIOUS</h5>
                                                </div>
                                            </div>
                                        </Link>    
                                            }
                                        </div>
                                        <div className="col-6">
                                            { this.state.next_article &&
                                            <Link to={"/article/"+this.state.next_article.id}>
                                            <div className="media post-controller-right d-flex">
                                                <div className="media-body align-self-center">
                                                    <h5 className="media-heading">NEXT</h5>
                                                </div>
                                                <div className="media-right align-self-center">
                                                    <img src={baseArtImgUrl+this.state.next_article.article_image}  width="100%" />
                                                </div>
                                            </div>
                                            </Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* post comment section */}
                                <div className="post-comment-div">
                                    {
                                    (this.state.comments.length > 0)?( 
                                        <h5 className="comment-title">COMMENTS</h5>
                                        ) : ( 
                                            <div>
                                                <h5 className="comment-title">COMMENTS</h5>
                                                <div className="comment-box text-center"> No comments to read.
                                                </div>
                                            </div>
                                        ) 
                                    }
                                    
                                    { this.state.comments.length > 0 &&
                                    <div>
                                        {
                                            this.state.comments.map((comment, index)=>{
                                            return(
                                                <div className="comment-box">
                                                    <div className="media" key={index}>
                                                        <div className="media-left">
                                                            <div className="commenter-img media-object">{comment.name[0].toUpperCase() }</div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <h5 className="commenter">{comment.name}</h5>
                                                                </div>
                                                                <div className="col-6">
                                                                    <h5 className="comment-date">
                                                                        {comment.comment_date} 
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                            <p className="comment-text">{comment.comment_text} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                                </div>
                                {/* post comment-form */}
                                <div className="comment-form-box">
                                    <h5 className="comment-title">POST A COMMENT</h5>
                                    <div className="commentAlert">{ this.state.alertmsg ? <AlertMessage message={this.state.alertmsg} type={this.state.msgtype}/> : null }</div>
                                    <form className="comment-form row" method="POST" onSubmit={this.submitComment}>
                                        <div className="form-group col-md-6">
                                        <input className="form-control" required type="text" value={this.state.commentername} onChange={this.commenterName} placeholder="Your name *" />
                                        </div>
                                        <div className="form-group col-md-6">
                                            <input className="form-control" required type="email" value={this.state.commenteremail} onChange={this.commenterEmail} placeholder="Your email *" />
                                        </div>
                                        <div className="form-group col-md-12">
                                            <textarea className="form-control" required value={this.state.commentercomment} onChange={this.commenterComment} rows="5" placeholder="Your comment *" ></textarea>
                                        </div>
                                        <div className="col-md-12">
                                            <button className="btn blue-btn" type="submit">
                                                SUBMIT
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            {/* right column */}
                            <div className="col-md-3 d-md-none d-lg-block">
                                <div className="hr_text footer-head"><span>SHARE THIS</span></div>
                                <ul className="social-icon">
                                    <li>
                                        <WhatsappShareButton
                                            url={shareUrl}
                                            quote={title}
                                            separator=":: "
                                        >
                                            <i className="fa fa-whatsapp" aria-hidden="true"></i> <span>WHATSAPP</span>
                                       
                                        </WhatsappShareButton>
                                    </li>
                                    <li>
                                        <FacebookShareButton
                                            url={shareUrl}
                                            quote={title}
                                        >
                                        <i className="fa fa-facebook" aria-hidden="true"></i> <span>FACEBOOK</span>
                                        </FacebookShareButton>
                                    </li>
                                    <li>
                                        <TwitterShareButton
                                            url={shareUrl}
                                            quote={title}
                                        >
                                        <i className="fa fa-twitter" aria-hidden="true"></i> <span>TWITTER</span>
                                        </TwitterShareButton>
                                    </li>
                                    <li>
                                        <PinterestShareButton
                                            url={String(window.location)}
                                            media={ baseArtImgUrl+this.state.article.article_image}
                                        >
                                        <i className="fa fa-pinterest-p" aria-hidden="true"></i> <span>PINTEREST</span>
                                        </PinterestShareButton>
                                    </li>
                                </ul>
                                <div className="hr_text footer-head d-none d-md-block"><span>LATEST POST</span></div>
                                    <div className="newpost-wrap d-none d-md-block">
                                        {
                                        this.state.latest_articles.map((lat_art, index)=>{
                                            return(
                                            <Link to={"/article/"+lat_art.id} key={lat_art.id}>
                                            <div className="media new-post d-flex">
                                                <div className="media-left align-self-center">
                                                    <img src={baseArtImgThumbUrl+lat_art.article_thumb} width="100%" />
                                                </div>
                                                <div className="media-body align-self-center">
                                                    <h5 className="media-heading">{lat_art.title}</h5>
                                                </div>
                                            </div>
                                            </Link>
                                            )
                                            })
                                        }
                                    </div>
                                <div className="hr_text footer-head  d-none d-md-block"><span>CATEGORIES</span></div>
                                <ul className="cat-list d-none d-md-block">
                                {
                                    this.state.categories.map((category, index)=>{
                                    return(
                                          <Link to=' ' key={category.id} onClick={this.getCategoryInputValue.bind(this,this.urlencode(category.name))}><li>{category.name}</li></Link>
                                        )
                                    })
                                }
                                </ul>
                                <div className="hr_text footer-head"><span>TAGS</span></div>
                                <ul className="post-tags">
                                {
                                    this.state.article_tags.map((tags, index)=>{
                                    return(
                                            <li key={tags.id}>{tags.tags}</li>
                                            )
                                        })
                                }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                {/* related posts section */}
                <div className="container-fluid">
                    <div className="row d-flex related-box">
                        <div className="col-md-2 align-self-center d-md-none d-lg-block">
                            <h5 className="related-title">YOU MAY AlSO LIKE TO READ</h5>
                        </div>
                        <div className="col-md-12 col-lg-10">
                            <div className="related-posts-slider">
                            <Relatedpost/>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
         );
    }
}
 
export default Blogdetail;