import React, { Component } from 'react';
import axios from 'axios';
import "./home.css";
import {Link} from 'react-router-dom';
import Masonry from 'react-masonry-css';
import Loader from "../loader/loaderComponent";
import loader from '../../assets/images/cruise-loader.gif';
const baseArtImgUrl = "https://proweb.ae/cruisemag/public/uploads/admin/article_image/";
const baseArtImgThumbUrl = "https://proweb.ae/cruisemag/public/uploads/admin/article_image/thumbnails/";
const baseApiUrl = "https://proweb.ae/cruisemag/";
// masonry grid script
const breakpointColumnsObj = {
    default: 6,
    1400: 5,
    768: 3,
    500: 2
  };
class Home extends Component {

    constructor(props){
        super(props);
        this.state={
            location: '',
            results: [],
            quotes:[],
            loading: false,
            message: '',
            totalResults: 0,
            totalPages: 0,
            currentPageNo: 0,
            lastPageNo : 0,
        }
        this.cancel = '';
    }
    
    componentDidMount() {
        if(this.state.currentPageNo == 0)
        {
            this.setState({ loading: true, message: '' }, () => {
                this.fetchSearchResults(1);
            });
        }
        else
        {
            this.setState({ loading: true, message: '' }, () => {
                this.fetchSearchResults(this.state.currentPageNo);
            });
        }
        axios.get(baseApiUrl+'api/quotes').then(response=>{
            this.setState({quotes:response.data.data});
        });
        window.scrollTo({top: 0, behavior: 'smooth'});
  }
    onLoadMore = () => {
        this.setState({ loading: true, message: '' }, () => {
            this.fetchSearchResults(Math.floor(this.state.currentPageNo+1),1);
        });
    }
    fetchSearchResults = (updatedPageNo = '',loadmore = 0) => {
      const pageNumber = updatedPageNo ? `?page=${updatedPageNo}` : '';
      const itemperpage = 12;
      var searchUrl = `${baseApiUrl}api/search-article/${itemperpage}${pageNumber}`;
      if (this.cancel) {
          // Cancel the previous request before making a new request
          this.cancel.cancel();
      }
      // Create a new CancelToken
      this.cancel = axios.CancelToken.source();
      axios
          .get(searchUrl, {
              cancelToken: this.cancel.token,
          })
          .then((res) => {
              const total = res.data.meta.total;
              const lastpage = res.data.meta.last_page;
              const totalPagesCount = this.getPagesCount( total, itemperpage );
              const resultNotFoundMsg = !res.data.data.length
                  ? 'There are no more search results. Please try a new search.'
                  : '';
                if(loadmore == 1){
                    res.data.data.map((results) => {
                        this.state.results.push(results);
                    });
                }
                else
                {
                    this.setState({ results: res.data.data }); 
                }
              this.setState({
                  totalResults: total,
                  currentPageNo: updatedPageNo,
                  lastPageNo:lastpage,
                  totalPages: totalPagesCount,
                  message: resultNotFoundMsg,
                  loading: false,
              });
          })
          .catch((error) => {
              if (axios.isCancel(error) || error) {
                  this.setState({
                      loading: false,
                      message: 'Failed to fetch results.Please check network',
                  });
              }
          });
  };
  getPagesCount = (total, denominator) => {
      const divisible = total % denominator === 0;
      const valueToBeAdded = divisible ? 0 : 1;
      return Math.floor(total / denominator) + valueToBeAdded;
  };

render() { 
return ( 
<div className="container-fluid bg-home page-gap">
    {/* loader */}
    { this.state.loading && <Loader/> }
    
    <div className="gallery">
        <Masonry breakpointCols={breakpointColumnsObj} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
         {
            this.state.results.map((article, index)=>{
            return(
            <Link to={"/article/"+article.id} key={index}>
                <div className="card">
                    <div className="card-img-box">
                        <img className="card-img-top" src={baseArtImgThumbUrl+article.article_thumb} alt="Card image cap" />
                        <div className="card-overlay">
                            {/*<div className="card-overlay-content">
                                <i className="fa fa-share" aria-hidden="true"></i>
                                <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                            </div>*/}
                        </div>
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">{article.title}</h5>
                        <p className="card-text">{article.category}</p>
                    </div>
                </div>
            </Link>
                        )
                    })
                }
        </Masonry>
    </div>
    <div className="text-center">
        { this.state.currentPageNo !== this.state.lastPageNo &&
        <button className="btn blue-btn" onClick={this.onLoadMore} type="submit"> Load More</button>
        }
    </div>
</div>
);
}
}
export default Home;