import React, { Component } from 'react';
import "./assets/css/style.css";
import Navigation from "./components/navigation/navigationComponent";
import Home from "./components/home/homeComponent";
import Footer from "./components/footer/footerComponent";
import About from "./components/about/aboutComponent";
import Contact from "./components/contact/contactComponent";
import Termsconditions from "./components/terms&conditions/terms&conditionsComponent";
import Privacy from "./components/privacypolicy/privacypolicyComponent";
import Blogdetail from "./components/blogdetail/blogdetailComponent";
import SearchResult from "./components/search_result/searchResultComponent";
import Thankyou from "./components/thankyou/thankyouComponent";
import {Route, Router, browserHistory} from 'react-router-dom';
import history from './history';
class App extends Component {
  constructor( props ) {
    super( props );
		this.state = {
            query: '',
            location: '',
            category : '',
        };
  }
  handleOnInputChange = (query , location) => {
      this.setState({category:''});
      this.setState({ query : query, location: location} , () => {
          if(query) history.push('/search/'+query);
          if(location) history.push('/search/'+location);
          if(this.state.category) history.push('/search/'+this.state.category); 
          if(!query && !location && !this.state.category) history.push('/search');
    });
  };
  handleCategoryChange = (category) =>{
    this.setState({location:''});
    this.setState({ category: category } , () => {
          if(category) history.push('/search/'+category);
    });
  }
  

  render() {
    // var keyCount  = Object.keys(this.state.results).length;
      return (
        <Router history={history}>
            <React.Fragment>
                <Navigation searchInput = {this.handleOnInputChange}/>
                <Route path="/" exact component={Home} />
                {this.state.query &&
                <Route path="/search/:query?" exact component={SearchResult} />
                }
                {this.state.location &&
                <Route path="/search/:location?" exact component={SearchResult} />
                }
                {this.state.category &&
                <Route path="/search/:category?" exact component={SearchResult} />
                }
                {!this.state.location && !this.state.query && !this.state.category &&
                <Route path="/search" exact component={SearchResult} />
                }
                {/* 
                <Route path="/article/:id" exact component={Blogdetail} />
                */}
                <Route path="/article/:id" render={props =>
                <Blogdetail {...props} searchInput = {this.handleCategoryChange} />
                } />
                <Route path="/about" exact component={About} />
                <Route path="/contact" exact component={Contact} />
                <Route path="/terms&conditions" exact component={Termsconditions} />
                <Route path="/privacypolicy" exact component={Privacy} />
                <Route path="/thankyou" exact component={Thankyou} />
                <Footer />
            </React.Fragment>
        </Router>
      );
  }
}

export default App;
