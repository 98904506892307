import React, { Component } from "react";
import axios from 'axios';
import $ from 'jquery';
import Navsearch from "../nav_search/navsearchComponent";
import logo from '../../assets/images/logo.png';
import "./navigation.css";
import {Link} from 'react-router-dom';
import { NavLink } from 'react-router-dom';
const baseApiUrl = "https://proweb.ae/cruisemag/";
// mobile menu button on click close script
$(document).ready(function () {
    $(document).click(
        function (event) {
            var target = $(event.target);
            var _mobileMenuOpen = $("#MainNav").hasClass("show");
            if (_mobileMenuOpen === true && !target.hasClass("navbar-toggler")) {
                $(".menu-btn.navbar-toggler").click();
            }
        }
    );
    $('.navbar-toggler').click(function(){
        $(this).find('i').toggleClass('fa-bars fa-times')
    });
});
// mobile search button on click close script
$(document).on('click', function (e){
    var menu_opened = $('#mobile_search').hasClass('show');
    if(!$(e.target).closest('#mobile_search').length &&
        !$(e.target).is('#mobile_search') &&
        menu_opened === true){
            $('#mobile_search').collapse('toggle');
    }
});
class Navigation extends Component {
    constructor(props){
        super(props);
        this.state={
            locations:[],
            loc_id:''
        }
    }
    getExploreInputValue = (explore) =>{
        this.setState({loc_id:explore} , () => this.getSearchInputValue());
    }
    getSearchInputValue = (input) =>{ 
        this.setState({loc_id:''});
        const location = this.state.loc_id;
        this.props.searchInput(input,location);
    }
    componentDidMount(){
        axios.get(baseApiUrl+'api/location').then(response=>{
            this.setState({locations:response.data.data});
        });
    }
    urlencode(str) {
        str = (str + '').toString();
        str = str.toLowerCase();
        
        return encodeURIComponent(str)
          .replace('!', '%21')
          .replace('\'', '%27')
          .replace('(', '%28')
          .replace(')', '%29')
          .replace('*', '%2A')
          .replace('%20', '-')
          .replace('%20', '-')
          .replace('%20', '-')
          .replace('%20', '-')
          .replace('%20', '-');
      }
render() {
return (
<React.Fragment>
    <nav className="navbar navbar-expand-lg main-nav fixed-top">
        <Link to="/" className="navbar-brand">
            <img src={logo} className="navlogo"></img>
        </Link>
        <button className="navbar-toggler mbl-search-toggle" type="button" data-toggle="collapse" data-target="#mobile_search" aria-controls="#mobile_search" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon">
            <i className="fa fa-search" aria-hidden="true"></i>
            </span>
        </button> 
        <button className="navbar-toggler menu-btn" type="button" data-toggle="collapse" data-target="#MainNav" aria-controls="#MainNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon">
            <i className="fa fa-bars" aria-hidden="true"></i>
            </span>
        </button>            
        <div className="collapse navbar-collapse" id="mobile_search">
            <Navsearch searchInput = {this.getSearchInputValue} />
        </div>
        <div className="d-none d-md-block search-div">
            <Navsearch searchInput = {this.getSearchInputValue} />
        </div>        
        <div className="collapse navbar-collapse" id="MainNav">
            <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                    <NavLink activeClassName='is-active' to="/about" className="nav-link">
                        ABOUT
                    </NavLink>
                </li>
                <li className="nav-item">
                    <div className="dropdown loc-dd">
                        <button type="button" className="btn-loc dropdown-toggle" data-toggle="dropdown">
                            EXPLORE
                        </button>
                        <div className="dropdown-menu">
                        {
                            this.state.locations.map((location, i)=>{
                            return(
                                <NavLink activeClassName='is-active' to={"/search/"+this.urlencode(location.city)}  className="dropdown-item" key={i} onClick={this.getExploreInputValue.bind(this,this.urlencode(location.city))}>{location.name}</NavLink>
                                // <a className="dropdown-item" href="/" key={i} onClick={this.getExploreInputValue.bind(this,location.id)}>{location.name}</a>
                            )})
                            
                        }
                        </div>
                    </div>
                </li>
                <li className="nav-item">
                    <NavLink activeClassName='is-active' to="/contact" className="nav-link">
                    CONTACT US
                    </NavLink>
                </li>
            </ul>
        </div>
    </nav>
</React.Fragment>
);
}
}
export default Navigation;