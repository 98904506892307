import React, { Component } from 'react';
import axios from 'axios';
import Loader from "../loader/loaderComponent";
import ReactHtmlParser from 'react-html-parser';
import "../about/about.css";
const baseApiUrl = "https://proweb.ae/cruisemag/";
class Privacy extends Component {
    constructor(){
        super();
        this.state={
            loading: false,
            privacy:[],
        }
    }
    componentDidMount(){
        this.setState({ loading: true, message: '' }, () => {
            axios.get(baseApiUrl+'api/cms/5').then(response=>{
                this.setState({privacy:response.data.data});
                this.setState({ loading: false, });
            });
            window.scrollTo({top: 0, behavior: 'smooth'});
        });
    }
    render() { 
        return ( 
            <div className="container-fluid bg-layout page-gap d-flex">
                {/* loader */}
                { this.state.loading &&  <Loader/> }

                <div className="container align-self-center">
                    <div className="page-layout">
                    <h2 className="sec-heading">{this.state.privacy.title}</h2>
                        <div>{ ReactHtmlParser(this.state.privacy.content) }</div>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default Privacy;