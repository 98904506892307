import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import "./nav_search.css";
const baseArtImgUrl = "https://proweb.ae/cruisemag/public/uploads/admin/article_image/";
const baseApiUrl = "https://proweb.ae/cruisemag/";

class Navsearch extends Component {
    constructor( props ) {
		super( props );
		this.state = {
			query: '',
        }
        this.handleOnInputChange = this.handleOnInputChange.bind(this);
    }
    
    handleOnInputChange(event){
        const query = event.target.value;
        this.setState({ query : query } );
        this.props.searchInput(query);
    }
    render() { 
        const { query } = this.state;
        return ( 
            <form className="form-inline search-form">
                <input className="form-control search-activity"
                 type="text" 
                 value={query}
                 placeholder="Things to do, places to visit"
                 onChange={this.handleOnInputChange}
                  />
                {/* <input className="form-control search-city" type="text" placeholder="City" /> */}
                <button className="btn blue-btn search-btn" type="button">
                    <i className="fa fa-search" aria-hidden="true"></i> Search
                </button>
            </form>
            
         );
    }
}
 
export default Navsearch;