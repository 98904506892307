import React, { Component } from 'react';
import "./thankyou.css";
import img from '../../assets/images/bg-graffiti.png';
import {Link} from 'react-router-dom';
class Thankyou extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="bg-lp">
                <div className="content">
                    <h3 className="heading bg-logo">Thank you!</h3>
                    <p>We appreciate you for taking the time to contact us. We will get in touch with you soon!</p>
                    <div class="text-center">
                        <Link to="/">
                            <button class="btn blue-btn" type="submit">Back to Homepage</button>
                        </Link>
                    </div>
                </div>
                <div className="bg-5">
                    <img src={img}></img>
                </div>
            </div>
         );
    }
}
export default Thankyou;