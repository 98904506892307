import React, { Component } from 'react';
import "./loader.css";
import loader from '../../assets/images/cruise-loader.gif';
class Loader extends Component {
    constructor(){
        super();
    }
    render() { 
        return ( 
                <div className="loader-overlay">
                    <img src={loader}></img>
                </div>
         );
    }
}
export default Loader;